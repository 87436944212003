@use "../../foundation/import" as *

/* -------------------------------
 *  html elements
 * ---------------------------- */
noscript
  background: #ff0
  font-weight: bold
  position: fixed
  z-index: 9999
  top: 0
  left: 0
  width: 100%
p:not(:last-child)
  margin: 0 0 var(--space-S) 0
a:link,
a:visited,
a:hover,
a:active
  color: inherit
img
  max-width: 100%
  height: auto

/* -------------------------------
 *  other settings
 * ---------------------------- */
