@use "../../foundation/import" as *

/* -------------------------------
 *  common
 * ---------------------------- */
.p-background-white
  background: $color-white
.p-background-beige
  background: $color-beige

/* -------------------------------
 *  front-page
 * ---------------------------- */
.p-front-page    
  .p-main-visual
    position: relative
    .p-background-section
      margin: 0 -32px
      padding: 0 0 20px 0
      .__background
        display: block
        height: 100%
        .__image
          width: 91.54%
    .p-foreground-section
      position: absolute
      top: 0
      left: 0
      width: 100%
      .__block-depth-1
        padding: 40px 0 0 0
        .__title
          .__image
            max-width: 600px
            width: 55%
        +media-query(md)
          padding: 20px 0 0 0
          .__title
            .__image
              width: 80%
              margin: 12% 0 0 0
  .p-banner-section
    margin: 40px 32px 0 32px
    +media-query(md)
      margin: 0
      border-top: 30px solid $color-cyan
    .__catch
      text-align: center
      font-weight: bold
      background: $color-cyan
      padding: 15px
      text-align: center
      font-size: 2.5rem
      color: $color-white
      +media-query(md)
        margin: 0
        font-size: 1.4rem
    .__block-depth-1
      padding: 20px 0
      a
        display: block
        // transition: transition(opacity)
        // +hover-style()
        //   opacity: 0.5
        img
          width: 100%
      .__block-depth-2
        padding: 0 20px
      .__button
        background: #ff5863
        display: block
        max-width: 860px
        margin: 20px auto 0 auto
        text-decoration: none
        color: #fff4b9
        font-weight: bold
        font-size: $font-M
        padding: 15px 15px 25px 15px 
        border-radius: 99em
        box-shadow: $default-box-shadow
        position: relative
        text-align: center
        line-height: 1.2
        &::after
          content: "▼"
          position: absolute
          bottom: 5px
          left: 50%
          transform: translate(-50%, 0)
          line-height: 1
          font-size: $font-S
        span
          display: inline-block
        &.__button-blue
          background: #0077ff
          color: #fff
        &.__button-green
          background: #48ad00
          color: #fff
        &.__button-yellow
          background: #f8c600
          color: #000
      .__button-group 
        display: flex
        justify-content: center
        column-gap: 20px
        row-gap: 20px
        flex-wrap: wrap
        // max-width: 860px
        margin: 20px auto 0 auto
        .__item
          min-width: calc(25% - 15px)
          +media-query(md)
            width: 100%
          .__button
            height: 100%
            margin: 0
            .__large
              font-size: 1.2em
              & + span 
                font-weight: normal
          &.__item-full
            min-width: 100%
            .__button
              margin: 0 auto
  .p-section-01
    .l-wrapper
      padding: 0 0 50px 0
      +media-query(md)
        padding: 0 0 25px 0
    .__title
      background: $color-cyan
      margin: 0 -32px
      padding: 15px
      text-align: center
      font-size: 2.5rem
      color: $color-white
      +media-query(md)
        margin: 0
        font-size: 1.4rem
      span
        display: inline-block
    .__lead
      text-align: center
      margin: 30px 0 0 0
      font-size: 1.8rem
      +media-query(md)
        text-align: left
        font-weight: normal
        font-size: 1.2rem
        margin: 15px 0 0 0
        &.__lead--2nd
          text-align: center
      span
        display: inline-block
    .__headline
      margin: 30px 0 0 0
      font-size: 1.8rem
  .p-section-02
    .__block-depth-1
      &.__block-depth-1--1st
        .__title
          padding: 40px 0 0 0
          +media-query(md)
            padding: 20px 0 0 0
        .__lead
          margin: 35px 0 0 0
          text-align: center
          font-size: 1.8rem
          font-weight: bold
          +media-query(md)
            margin: 15px 0 0 0
            font-size: 1.4rem
        .__headline
          margin: 40px 0 0 0
          +media-query(md)
            margin: 15px 0 0 0
        .__sub
          display: none
          align-items: center
          margin: 20px 0 0 0
          +media-query(md) 
            display: flex 
          .__bar
            width: 100%
            height: 1px
            background: $color-dark-gray
            flex-shrink: 1
          .__text
            margin: 0 15px
            font-weight: bold
            font-size: 1.6rem
            flex-shrink: 0
      &.__block-depth-1--3rd
        margin: 60px 0 0 0
        +media-query(md)
          margin: 30px 0 0 0
          padding: 0 0 30px 0
        .__block-depth-2
          display: flex
          flex-wrap: wrap
          .__block-depth-3
            height: auto
            .__box
              background: $color-white
              border-radius: 23px
              height: 100%
              padding: 20px 30px
              +media-query(md)
                padding: 10px 20px 15px 20px
          &.__block-depth-2--1st
            justify-content: space-between
            .__block-depth-3
              width: 47.6851%
              margin: 40px 0 0 0
              +media-query(md)
                width: 100%
                margin: 15px 0 0 0
          &.__block-depth-2--2nd
            justify-content: flex-start
            .__block-depth-3
              margin: 40px 0 0 0
              padding: 0 0 40px 0
              +media-query(md)
                margin: 15px 0 0 0
                padding: 0
              &.__block-depth-3--add-illustration
                position: relative
                padding: 0 102px 40px 0
                width: calc(47.6851% + 102px)
                +media-query(md)
                  width: 100%
                  padding: 0
                .__illustration
                  position: absolute
                  right: 0
                  bottom: 0
                  +media-query(md)
                    display: none
              &:last-child
                flex-grow: 1
  .p-section-03
    .__block-depth-1
      padding: 50px 0
      +media-query(md)
        padding: 20px 0
      .__block-depth-2
        margin: 20px 0 0 0
        .__caption
          margin: 15px 0 0 0
          +media-query(md)
            font-size: 1.1rem
  .p-section-04
    .__title
      padding: 40px 0 0 0
      +media-query(md)
        padding: 20px 0 0 0
    .__block-depth-1
      display: flex
      justify-content: space-between
      flex-wrap: wrap
      position: relative
      padding: 0 0 70px 0
      +media-query(md)
        padding: 0 0 20px 0
      .__block-depth-2
        width: calc(50% - 25px)
        margin: 30px 0 0 0
        +media-query(md)
          width: 100%
          margin: 20px 0 0 0
          padding: 0 20px
        .__box
          background: $color-white
          border-radius: 23px
          padding: 15px 30px 30px 30px
          +media-query(md)
            padding: 15px
          .__thumbnail
            text-align: center
            padding: 0 40px
            margin: 15px 0 0 0
            +media-query(md)
              padding: 0
      .__illustration
        display: inline-block
        position: absolute
        bottom: 0
        left: 50%
        transform: translate(-60%, -25%)
        +media-query(md)
          bottom: auto
          left: auto
          right: 0
          top: 0
          max-width: 25vw
          transform: translate(0, -4vw)
    .__button
      padding: 0 0 40px 0
      +media-query(md)
        padding: 0 0 20px 0
  .p-section-05
    .__block-depth-1
      padding: 30px 0
      +media-query(md)
        padding: 30px 0 20px 0
      .__notice
        margin: 30px 0 0 0
        background: #FDF58D
        border: 1px solid $color-cyan
        border-radius: 15px
        padding: 15px 10px
        +media-query(md)
          margin: 15px 0 0 0
          font-size: 1.2rem
  .p-section-06
    .__block-depth-1
      padding: 30px 0 45px 0
      +media-query(md)
        padding: 30px 0
      .__block-depth-2
        max-width: 760px
        margin: 20px auto 30px auto
        .__box
          background: $color-white
          border: 1px solid $color-cyan
          border-radius: 22px
          padding: 50px 80px
          position: relative
          +media-query(md)
            padding: 30px 45px
          .__illustration
            position: absolute
            left: 0
            top: 50%
            transform: translate(-50%, -50%)
            +media-query(md)
              display: none