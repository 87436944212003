/* -------------------------------
 *  color scheme
 * ---------------------------- */
$base-color-list: (1: #EEEEEE, 2: #C8C8C8, 3: #ACACAC, 4: #7B7B7B, 5: #5C5C5C, 6: #333333) !default
@function base-color($base-color-key)
  @return #{map-get($base-color-list, $base-color-key)}
$primary-color-list: (1: #C0FFFF, 2: #6BFFFF, 3: #00FFFF, 4: #00D2D2, 5: #008D8D, 6: #004E4E) !default
@function primary-color($primary-color-key)
  @return #{map-get($primary-color-list, $primary-color-key)}
$secondary-color-list: (1: #FFFF8E, 2: #FFFF00, 3: #E5E500, 4: #C3C300, 5: #767600, 6: #3F3F00) !default
@function secondary-color($secondary-color-key)
  @return #{map-get($secondary-color-list, $secondary-color-key)}
$accent-color-list: (1: #FFD9FF, 2: #FF9AFF, 3: #FF59FF, 4: #FF00FF, 5: #C400C4, 6: #6E006E) !default
@function accent-color($accent-color-key)
  @return #{map-get($accent-color-list, $accent-color-key)}
$color-white: #FFF !default
$color-black: #000 !default
$color-gray: #707070 !default
$color-light-gray: #C8C8C8 !default
$color-dark-gray: #1A1311 !default
$color-dark-brown: #58393A !default
$color-beige: #F1F0EA !default
$color-cyan: #20ABC2 !default
$color-cyan-dark: #24777B !default
$color-cyan-light: #92D6E6 !default
$color-green: #B2C446 !default
$color-green-light: #EDEB71 !default
$color-darkgreen: #539A90 !default
$color-green-soft: #D2DD94 !default
$color-blue: #4D9EE4 !default
$color-blue-light: #A3D3D9 !default
$color-blue-soft: #C3DEF5 !default
$color-yellow: #FBCA16 !default
$color-orange: #ffa046 !default

/* -------------------------------
 *  gradation color 
 * ---------------------------- */
@function linear-gradation-color($angle: 0deg, $start-color: white, $end-color: black)
  @return linear-gradient($angle, $start-color 0%, $end-color 100%)  
@function radial-gradation-color($start-color: white, $end-color: black, $option: circle farthest-corner at center)
  @return radial-gradient($option, $start-color 0%, $end-color 100%)  

/* -------------------------------
 *  UI color 
 * ---------------------------- */
$ui-color-list: (error: #B00, warning: #DA0, notice: #0CE, success: #0C4, negative: #AAA) !default
@function ui-color($ui-color-key)
  @return #{map-get($ui-color-list, $ui-color-key)}

/* -------------------------------
 *  SNS brand color
 * ---------------------------- */
$sns-color-list: (facebook: #315096, twitter: #55acee, instagram: #D93177, tumblr: #36465d, googleplus: #dd4b39, line: #00b900, hatena: #008fde, youtube: #cd201f, pinterest: #bd081c, pocket: #dc3d50, linkedin: #0079ba, feedly: #6cc655) !default
@function sns-color($sns-color-key)
  @return #{map-get($sns-color-list, $sns-color-key)}