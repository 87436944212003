@use "sass:math" as *

/* -------------------------------
 *  margin
 * ---------------------------- */

/* -- config ------------------ */
$unit-8: 8 !default
$unit-16: 16 !default
$unit-32: 32 !default

/* -- class name -------------- */
.mg-000
  margin: 0 !important
@for $i from 0 through 10
  @if $i < 2
    .mgl-00#{$i * $unit-8}
      margin-left: #{$i * $unit-8}px !important
  @else if $i < 4
    .mgl-0#{$i * $unit-8}
      margin-left: #{$i * $unit-8}px !important
  @else if $i < 7
    .mgl-0#{($i - 2) * $unit-16}
      margin-left: #{($i - 2) * $unit-16}px !important
  @else if $i < 8
    .mgl-0#{($i - 4) * $unit-32}
      margin-left: #{($i - 4) * $unit-32}px !important
  @else if $i > 8
    .mgl-#{($i - 5) * $unit-32}
      margin-left: #{($i - 5) * $unit-32}px !important
@for $i from 0 through 10
  @if $i < 2
    .mgr-00#{$i * $unit-8}
      margin-right: #{$i * $unit-8}px !important
  @else if $i < 4
    .mgr-0#{$i * $unit-8}
      margin-right: #{$i * $unit-8}px !important
  @else if $i < 7
    .mgr-0#{($i - 2) * $unit-16}
      margin-right: #{($i - 2) * $unit-16}px !important
  @else if $i < 8
    .mgr-0#{($i - 4) * $unit-32}
      margin-right: #{($i - 4) * $unit-32}px !important
  @else if $i > 8
    .mgr-#{($i - 5) * $unit-32}
      margin-right: #{($i - 5) * $unit-32}px !important
@for $i from 0 through 10
  @if $i < 2
    .mgt-00#{$i * $unit-8}
      margin-top: #{div($i * $unit-8, 10)}px !important
  @else if $i < 4
    .mgt-0#{$i * $unit-8}
      margin-top: #{div($i * $unit-8, 10)}px !important
  @else if $i < 7
    .mgt-0#{($i - 2) * $unit-16}
      margin-top: #{($i - 2) * $unit-16}px !important
  @else if $i < 8
    .mgt-0#{($i - 4) * $unit-32}
      margin-top: #{($i - 4) * $unit-32}px !important
  @else if $i > 8
    .mgt-#{($i - 5) * $unit-32}
      margin-top: #{($i - 5) * $unit-32}px !important
@for $i from 0 through 10
  @if $i < 2
    .mgb-00#{$i * $unit-8}
      margin-bottom: #{$i * $unit-8}px !important
  @else if $i < 4
    .mgb-0#{$i * $unit-8}
      margin-bottom: #{$i * $unit-8}px !important
  @else if $i < 7
    .mgb-0#{($i - 2) * $unit-16}
      margin-bottom: #{($i - 2) * $unit-16}px !important
  @else if $i < 8
    .mgb-0#{($i - 4) * $unit-32}
      margin-bottom: #{($i - 4) * $unit-32}px !important
  @else if $i > 8
    .mgb-#{($i - 5) * $unit-32}
      margin-bottom: #{($i - 5) * $unit-32}px !important
