@use "../foundation/import" as *

/* -------------------------------
 *  config
 * ---------------------------- */
$container-gutter: var(--space-XS) !default
$grid-gutter: var(--space-R) !default
$grid-sidebar-min-width: auto !default
$grid-sidebar-max-width: 300px !default

/* -------------------------------
 *  container wrapper & inner
 * ---------------------------- */
.l-wrapper
  width: 100%
.l-inner
  padding: 0 20px
.l-outline-frame-area-outer
  margin: 0 32px
  width: calc(100% - 64px)  
  +media-query(md)
    margin: 0
    width: 100%
.l-outline-frame-area-inner  
  max-width: 1302px
  margin: 0 auto
  padding: 0 32px
  +media-query(md)
    padding: 0

/* -------------------------------
 *  gird system (container)
 * ---------------------------- */
.l-container
  width: 100%
  max-width: 1124px
  margin: 0 auto
.l-container-full
  width: 100%
  max-width: 100%
  margin: 0
+media-query(xxl)
  .l-container
    max-width: 1124px
+media-query(xl)
  .l-container
    max-width: 100%
+media-query(lg)
  .l-container
    max-width: 100%
+media-query(md)
  .l-container
    max-width: 100%
+media-query(sm)
  .l-container
    max-width: 100%
+media-query(xs)
  .l-container
    max-width: 100%
+media-query(xxs)
  .l-container
    max-width: 100%

/* -------------------------------
 *  gird system (flexbox)
 * ---------------------------- */
.l-flex-container
  display: flex
  justify-content: space-between
  flex-wrap: wrap
  .l-flex-item
    padding: 0 $container-gutter
    flex-basis: auto
    max-width: none
    flex-grow: 1
    flex-shrink: 1
    &.__no-gutter
      padding: 0
    &.__col-1
      width: 8.3333%
    &.__col-2
      width: 16.6666%
    &.__col-3, &.__quad
      width: 25%
    &.__col-4
      width: 33.3333%
    &.__col-5
      width: 41.6666%
    &.__col-6, &.__half
      width: 50%
    &.__col-7
      width: 58.3333%
    &.__col-8
      width: 66.6666%
    &.__col-9, &.__quad-of-3
      width: 75%
    &.__col-10
      width: 83.3333%
    &.__col-11
      width: 91.6666%
    &.__col-12
      width: 100%
    +media-query(switch)
      &.__col-1,
      &.__col-2,
      &.__col-3,&.__quad,
      &.__col-4,
      &.__col-5,
      &.__col-6,&.__half,
      &.__col-7,
      &.__col-8,
      &.__col-9,&.__quad-of-3,
      &.__col-10,
      &.__col-11
        width: 100%

/* -------------------------------
 *  gird system (grid layout)
 * ---------------------------- */
.l-grid-container
  display: grid
  padding: 0 calc(#{$container-gutter} * 2)
  &.__col-quad
    grid-template: auto / repeat(4, 1fr)
    grid-gap: $grid-gutter
  &.__col-triple
    grid-template: auto / repeat(3, 1fr)
    grid-gap: $grid-gutter
  &.__col-double
    grid-template: auto / repeat(2, 1fr)
    grid-gap: $grid-gutter
  &.__col-single
    grid-template: auto / 100%
  &.__col-sidebar-left
    grid-template: (auto / minmax($grid-sidebar-min-width, $grid-sidebar-max-width)) 1fr
    grid-gap: $grid-gutter
  &.__col-sidebar-right
    grid-template: (auto / 1fr) minmax($grid-sidebar-min-width, $grid-sidebar-max-width)
    grid-gap: $grid-gutter
  +media-query(switch)
    &.__col-quad,
    &.__col-triple,
    &.__col-double,
    &.__col-sidebar-left,
    &.__col-sidebar-right
      grid-template: auto / 100%
      grid-gap: 0
  .l-grid-item
    margin: 0
    padding: 0
