@use "../../foundation/import" as *

/* -------------------------------
 *  list components
 * ---------------------------- */
.u-list-category
  display: flex   
  justify-content: flex-start
  flex-wrap: wrap
  margin: 10px 0 0 0
  .__item
    width: calc(16.6666% - 21.6666px)
    background: $color-white
    border-radius: 14px
    margin: 26px 26px 0 0
    padding: 15px 5px
    display: flex
    flex-direction: column
    &:nth-child(6n)
      margin: 26px 0 0 0
    +media-query(xl)
      width: calc(20% - 20.8px)
      &:nth-child(6n)
        margin: 26px 26px 0 0
      &:nth-child(5n)
        margin: 26px 0 0 0
    +media-query(lg)
      width: calc(25% - 19.5px)
      &:nth-child(5n)
        margin: 26px 26px 0 0
      &:nth-child(4n)
        margin: 26px 0 0 0
    +media-query(md)
      background: none
      padding: 0
      width: calc(25% - 4.5px)
      margin: 10px 6px 0 0
      &:nth-child(5n),
      &:nth-child(6n)
        margin: 10px 6px 0 0
      &:nth-child(4n)
        margin: 10px 0 0 0
    .__name 
      font-weight: bold
      font-size: 1.5rem
      line-height: 1.2
      min-height: calc(2em * 1.2)
      display: flex
      justify-content: center
      align-items: center
      flex-wrap: wrap
      +media-query(md)
        font-size: 1.2rem
        min-height: calc(3em * 1.2)
      span
        display: inline-block
        +media-query(md)
          flex-shrink: 0
          &.__small
            font-size: 1.0rem
    .__thumbnail
      text-align: center
      margin: 10px 0 0 0
      +media-query(md)
        padding: 0 4px
    .__caption
      font-size: 1.4rem
      line-height: 1.2
      margin: 15px 0 0 0
      +media-query(md)
        font-size: 1.0rem
      .__term
        text-align: center
        +media-query(md)
          font-weight: bold
      .__tags
        display: flex
        justify-content: center
        flex-wrap: wrap
        span
          +media-query(md)
            flex-shrink: 0
          &:not(:last-child)
            &::after
              content: "、"
    .__icon-wrap
      display: flex
      justify-content: flex-end
      padding: 0 1rem
      margin: auto 0 0 0
      
.u-list-contact-notice
  .__term
    text-align: center
    .__toggle-button
      display: inline-flex
      justify-content: space-around
      align-items: center
      min-width: 410px
      padding: 10px 25px
      border-radius: 99em
      border: 2px solid $color-cyan
      text-decoration: none
      +media-query(md)
        min-width: 100%
        padding: 5px 15px
      .__text
        font-weight: bold
        font-size: 1.8rem
        +media-query(md)
          font-size: 1.4rem
  .__body
    margin: 15px 0 0 0
    +media-query(md)
      font-size: 1.2rem
    .__list-child
      .__item
        .__breakline
          display: block
          padding: 0 0 0 1em

.u-list-contact-form
  .__item
    &:not(:first-child)
      margin: 30px 0 0 0
  .__term
    padding: 0 0 0 110px
    position: relative
    font-size: 2.0rem
    +media-query(md)
      padding: 0 0 0 50px
      font-size: 1.3rem
    &::before
      position: absolute
      left: 0
      top: 0
      width: 90px
      height: 40px
      display: flex
      justify-content: center
      align-items: center
      border-radius: 7px
      font-size: 2.0rem
      font-weight: bold
      +media-query(md)
        width: 40px
        height: 20px
        font-size: 1.1rem
    &.__term--required
      &::before
        content: "必須"
        background: $color-cyan
        color: $color-white
    &.__term--not-required
      &::before
        content: "任意"
        background: $color-light-gray
  .__body
    margin: 20px 0 0 0
    +media-query(md)
      margin: 10px 0 0 0
    .__note
      color: red
      font-size: 1.4rem
      margin: 15px 0 0 0
      +media-query(md)
        font-size: 1.1rem
        margin: 10px 0 0 0
      &.__note--big
        font-size: 1.8rem
        font-weight: bold
        +media-query(md)
          font-size: 1.4rem
    .__input-group
      .__input-set
        .__label
          font-size: 2.0rem
          +media-query(md)
            font-size: 1.3rem
        &.__input-set--horizontal
          display: flex
          align-items: center
        &.__input-checkbox,
        &.__input-checkbox .wpcf7-checkbox
          display: flex
          flex-direction: column
          & > *
            &:not(:first-child)
              margin: 10px 0 0 0
          .wpcf7-list-item
            margin-left: 0
      &.__input-name,
      &.__input-kana
        display: flex
        align-items: center
        .__input-set
          max-width: 200px
          &:first-child
            margin: 0 30px 0 0
            +media-query(md)
              margin: 0 10px 0 0
      &.__input-zipcode
        .__input-set
          +media-query(md)
            max-width: 110px
          .__label
            margin: 0 15px 0 0 
            +media-query(md)
              margin: 0 10px 0 0
        .wpcf7-form-control-wrap
          width: 100%
      &.__input-address-1st
        display: flex
        align-items: center
        margin: 20px 0 0 0
        +media-query(md)
          display: block
          margin: 10px 0 0 0
        .__input-set
          &.__input-set--1st,
          &.__input-set--2nd
            margin: 0 10px 0 0
          &.__input-set--1st
            width: 20%
            +media-query(md)
              margin: 0
          &.__input-set--2nd,
          &.__input-set--3rd
            width: 40%
            +media-query(md)
              margin: 10px 0 0 0
          &.__input-set--1st,
          &.__input-set--2nd,
          &.__input-set--3rd
            +media-query(md)
              width: 100%
      &.__input-address-2nd
        margin: 20px 0 0 0
        +media-query(md)
          margin: 10px 0 0 0
      &.__input-reason-other,
      &.__input-referral-other
        margin: 10px 0 0 0

.u-list-plan
  display: flex
  justify-content: center
  align-items: center
  +media-query(lg)
    flex-direction: column
  +media-query(md)
    flex-direction: row
  .__item
    margin: 15px 15px 0 15px
    width: 33.3333%
    +media-query(xl)
      margin: 15px 5px 0 5px
    +media-query(lg)
      width: 50%
    +media-query(md)
      width: 33.3333%
      margin: 15px 10px 0 10px
    .__item-inner
      position: relative
      padding-top: 100%
      &::before
        content: ""
        width: 100%
        height: 100%
        border-radius: 100%
        position: absolute
        left: 0
        top: 0
      .__text
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        position: absolute
        width: 100%
        height: 100%
        left: 0
        top: 0
        color: $color-white
        font-size: 2.0rem
        font-weight: bold
        +media-query(md)
          font-size: 1.2rem
        &.__text--small
          font-size: 1.8rem
          +media-query(md)
            font-size: 1.0rem
        span 
          line-height: 1
          +media-query(md)
            line-height: 1.4
  &.u-list-plan--green
    .__item-inner::before
      background: $color-green
  &.u-list-plan--darkgreen
    .__item-inner::before
      background: $color-darkgreen
  &.u-list-plan--blue
    .__item-inner::before
      background: $color-blue
  &.u-list-plan--yellow
    .__item
      +media-query(lg)
        width: 65%
      +media-query(md)
        width: 29%
    .__item-inner::before
      background: $color-yellow
      
.u-list-feature
  display: flex
  justify-content: center
  +media-query(md)
    flex-wrap: wrap
  .__item
    margin: 25px 6px 0 6px
    height: auto
    width: 14.2857%
    +media-query(md)
      width: calc(25% - 6px)
      margin: 15px 3px 0 3px
      flex-grow: 0
    .__text
      display: flex
      flex-direction: column
      align-items: center
      justify-content: center
      height: 100%
      border-radius: 25px
      padding: 40px 0
      font-size: 1.9rem
      font-weight: bold
      flex-shrink: 1
      position: relative
      +media-query(lg)
        font-size: 1.6rem
      +media-query(md)
        padding: 15px 0
        font-size: 1.1rem
        border-radius: 9px
      &.__text--small
        font-size: 1.7rem
        +media-query(lg)
          font-size: 1.4rem
        +media-query(md)
          font-size: 1.1rem
      &::before
        content: ""
        position: absolute
        width: 0
        height: 0
        border: 16px solid transparent
        bottom: 100%
        left: calc(50% - 16px)
        +media-query(md)
          border: 8px solid transparent
          left: calc(50% - 8px)
      span
        line-height: 1.2
        white-space: pre
    &:nth-child(odd)
      .__text
        background: $color-blue-light
        &::before
          border-bottom-color: $color-blue-light
    &:nth-child(even)
      .__text
        background: $color-green-light
        &::before
          border-bottom-color: $color-green-light
        
.u-list-notice
  margin: 30px 0 0 0     
  +media-query(md)
    margin: 15px 0 0 0
    font-size: 1.2rem  
  .__item
    .__breakline
      display: block
      padding: 0 0 0 1em

.u-list-price
  .__item      
    &:not(:first-child)
      margin: 20px 0 0 0
      padding: 20px 0 0 0
      border-top: 1px solid $color-cyan
      +media-query(md)
        margin: 15px 0 0 0
        padding: 15px 0 0 0
    .__term
      display: flex
      align-items: center
      +hover-style()
        cursor: pointer      
      .__text
        text-align: center
        font-size: 2.0rem
        font-weight: bold
        flex-grow: 1
        +media-query(md)
          font-size: 1.3rem
      .__toggle-button
        +media-query(md)
          width: 18px
          .__image
            vertical-align: middle

.u-list-aside-links
  display: flex
  flex-wrap: wrap
  justify-content: space-between  
  .__item
    width: calc(50% - 15px)
    margin: 15px 0 0 0
    +media-query(md)
      width: 100%
    &.__item--fullwidth
      width: 100%
    .__caption
      margin: 10px 0 0 0
      text-align: center
      +media-query(md)
        font-size: 1.0rem
      span
        display: inline-block