@use "../../foundation/import" as *

/* -------------------------------
 *  parts styles
 * ---------------------------- */
.c-scroll-top
  position: fixed
  right: 20px
  bottom: 60px
  width: 50px
  height: 50px
  z-index: 10
  background: $color-green
  border-radius: 100%
  font-size: 0
  box-shadow: $default-box-shadow
  &::before,
  &::after
    content: ""
    width: 18px
    height: 4px
    position: absolute
    top: calc(50% - 4px)
    left: calc(50% - 9px)
    background: $color-white
    border-radius: 4px
  &::before
    transform: translate(-5px, 0) rotate(-45deg)
  &::after
    transform: translate(5px, 0) rotate(45deg)

.c-fixed-cv 
  position: fixed
  left: 20px
  bottom: 20px
  z-index: 10
  .__button 
    background: $color-orange
    display: inline-block
    text-decoration: none
    color: #fff
    font-weight: bold
    font-size: $font-M
    padding: 15px 15px 25px 15px 
    border-radius: 99em
    box-shadow: $default-box-shadow
    position: relative
    text-align: center
    line-height: 1.2
    aspect-ratio: 1 / 1
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    width: 9.5em
    row-gap: 0.25em
    +media-query(md)
      font-size: $font-S
    .__sub
      color: $color-black
      font-size: 0.9em
    // &::after
    //   content: "▼"
    //   position: absolute
    //   bottom: 5px
    //   left: 50%
    //   transform: translate(-50%, 0)
    //   line-height: 1
    //   font-size: $font-S
    
.c-modal
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100vh
  z-index: 11
  display: flex
  justify-content: center
  align-items: center
  .__overlay
    width: 100%
    height: 100%
    background: rgba(0,0,0,0.7)
    position: absolute
    left: 0
    top: 0
    z-index: 1
  .__panel
    max-width: 800px
    width: 100%
    margin: 0 20px
    background: $color-white
    padding: 10px
    border-radius: 8px
    position: relative
    z-index: 2
    .__box
      max-height: calc(100vh - 60px)
      overflow: auto
    .__close
      font-size: 0
      position: absolute
      width: 30px
      height: 30px
      right: -15px
      top: -15px
      background: $color-black
      border-radius: 100%
      &::before,
      &::after
        content: ""
        width: 16px
        height: 4px
        border-radius: 4px
        background: $color-white
        position: absolute
        left: calc(50% - 8px)
        top: calc(50% - 2px)
      &::before
        transform: rotate(45deg)
      &::after
        transform: rotate(-45deg)

.c-accordion-details
  margin: 1em 0 0 0 
  summary
    display: flex
    align-items: center
    font-size: 20px
    +media-query(md)
      font-size: 14px
    &::-webkit-details-marker
      display: none
    img
      width: 1.5em

.c-dialog
  margin: auto
  border-radius: 10px
  padding: 3rem
  gap: 1.5rem
  max-width: 720px
  +media-query(md)
    max-width: calc(100% - 3rem)
  &::backdrop
    background: rgb(0 0 0 / 50%)
  .__box
    display: flex
    justify-content: flex-start
    align-items: center
    gap: 1.5rem
    +media-query(md)
      flex-direction: column
    .__thmbnail,
    .__contents
      flex: 1 1 0
    .__thmbnail
      text-align: center
      // aspect-ratio: 1 / 1
      .__image
        object-fit: contain
        height: 100%
    .__title
      font-size: 1.2em
      color: $color-cyan
    .__description 
      font-weight: bold
      li
        position: relative
        padding: 0 0 0 1em
        &::before
          content: "・"
          position: absolute
          left: 0
          top: 0
  .__button-wrap  
    display: flex
    justify-content: center
    align-items: center
    margin: 1.5rem 0 0 0
    .__button
      padding: 0.5em 2em
      line-height: 1
      background: #ddd
      border: 1px solid #999
      border-radius: 5px
  
/* -------------------------------
 *  javascript styles
 * ---------------------------- */   
.js-accordion-panel
  .js-accordion-panel-element
    display: none

.js-modal  
  display: none

.js-dialog-open
  &:hover
    cursor: pointer