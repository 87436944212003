@use "../foundation/import" as *

/* -------------------------------
 *  html body
 * ---------------------------- */
html
  width: 100%
  height: 100%
  font-size: 62.5%
  body
    width: inherit
    height: inherit
    min-height: 100vh
    font-family: $default-font-family
    font-size: $default-font-size
    line-height: $default-line-height
    color: $default-color
    // font-feature-settings: "palt"
    background: $color-cyan
    +media-query(md)
      background: $color-white
  +media-query(sm)
    body
      font-size: $font-S
  &:has(dialog[open])
    overflow: hidden

/* -------------------------------
 *  base layout
 * ---------------------------- */

/* -- config ------------------ */
$header-min-height: auto
$header-max-height: auto
$footer-min-height: auto
$footer-max-height: auto

/* -- layout ------------------ */
.l-base
  width: inherit
  height: auto !important
  min-height: 100vh
  position: relative
  overflow: hidden
  display: grid
  grid-template: "header" auto "main" 1fr "footer" (auto / 100%)