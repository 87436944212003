@use "../../foundation/import" as *

/* -------------------------------
 *  common
 * ---------------------------- */

/* -------------------------------
 *  contact
 * ---------------------------- */
.p-contact-page
  .p-section-01
    .__block-depth-1
      max-width: 740px
      margin: 0 auto
      padding: 60px 0 40px 0
      +media-query(md)
        padding: 20px 0

.p-thanks-page
  padding-block: 32px 0
  +media-query(md)
    padding-block: 0
  .l-wrapper
    border-radius: 25px
    +media-query(md)
      border-radius: 0
  .__block-depth-1 
    height: calc(100dvh - (32px * 2))
    display: flex
    justify-content: center
    align-items: center
  .__block-depth-2
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    row-gap: 40px
  .__title,
  .__message
    text-align: center
    span 
      display: inline-block
  .__button-wrap 
    display: flex
    justify-content: center
    align-items: center
    .__button
      padding: 0.5em 2em
      border-radius: 5px
      text-decoration: none
      color: $color-white
      background: $color-cyan
      font-weight: bold
  .__title
    font-size: 1.5em