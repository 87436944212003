@use "../foundation/import" as *

/* -------------------------------
 *  footer
 * ---------------------------- */
.l-footer
  grid-area: footer
  padding: 0 0 32px 0
  +media-query(md)
    padding: 0
  .l-wrapper
    border-radius: 0 0 25px 25px
    +media-query(md)
      border-radius: 0
    .__block-depth-1
      max-width: 646px
      margin: 0 auto
      .__block-depth-2
        .__box
          border: 2px solid $color-cyan
          padding: 20px 15px
          display: flex
          flex-direction: column
          align-items: center
          +media-query(md)
            padding: 20px 30px
          .__lead 
            text-align: center
            font-size: 1.4rem
            font-weight: bold
            color: $color-dark-brown
            span
              display: inline-block
          .__logos
            display: flex
            flex-direction: column
            align-items: center
            margin: 20px 0 0 0
            .__freecall
              margin: 10px 0 0 0
              padding: 10px 0 0 0
              border-top: 1px solid $color-dark-brown
              display: flex
              flex-direction: column
              align-items: center
              .__caption
                text-align: center
                margin: 10px 0 0 0
                font-size: 1.2rem
      .__copyright
        display: block
        margin: 30px 0 0 0
        padding: 0 0 20px 0
        text-align: center
        font-size: 1.4rem
  &.l-footer--contact-page
    .__title
      background: $color-cyan
      margin: 0 -32px
      padding: 15px
      text-align: center
      font-size: 2.5rem
      color: $color-white
      +media-query(md)
        margin: 0
        font-size: 1.8rem
      span
        display: inline-block       
    .__block-depth-1 
      .__block-depth-2
        padding: 60px 0 0 0 
        +media-query(md)
          padding: 20px 0 0 0 
        .__box
          .__logos
            margin: 10px 0 0 0