@use "../foundation/import" as *

/* -------------------------------
 *  header
 * ---------------------------- */
.l-header
  grid-area: header
  padding: 32px 0 0 0
  +media-query(md)
    padding: 0
  .l-wrapper
    border-radius: 25px 25px 0 0
    +media-query(md)
      border-radius: 0
      background: $color-beige
    .__block-depth-1
      .__block-depth-2
        &.__block-depth-2--1st
          .__block-depth-3
            display: flex
            justify-content: space-between
            align-items: center
            +media-query(md)
              justify-content: flex-end
            .__block-depth-4
              &.__block-depth-4--1st
                flex-shrink: 1
                margin: 25px 0 0 0
                +media-query(md)
                  display: none
              &.__block-depth-4--2nd
                flex-shrink: 0
                margin: 25px 0 0 20px
                +media-query(md)
                  flex-shrink: 1
                .__block-depth-5
                  .__block-depth-6
                    &.__block-depth-6--1st
                      display: flex
                      justify-content: space-between
                      align-items: center
                      .__organizer
                        margin: 0 5px 10px 0
                      .__sponsorship
                        margin: 0 0 10px 5px
                    &.__block-depth-6--2nd
                      display: flex
                      justify-content: space-between
                      align-items: center
                      border-top: 1px solid $color-dark-brown
                      +media-query(md)
                        display: none
                      .__freecall
                        margin: 10px 5px 0 0
                      .__business-hours
                        margin: 10px 0 0 5px
                        display: flex
                        flex-direction: column
                        align-items: center
                        line-height: 1.2
                        .__time
                          font-size: 1.2rem
                        .__caption
                          font-size: 1.0rem
  &.l-header--contact-page
    .l-wrapper
      background: $color-white
    .__block-depth-1
      padding: 70px 0 0 0
      max-width: 740px
      margin: 0 auto
      +media-query(md)
        padding: 20px 0 0 0
      .__logo
        text-align: center
        margin: 40px 0 0 0
        +media-query(md)
          margin: 20px 0 0 0
      .__lead
        margin: 30px 0 0 0
        background: #FDF58D
        border: 1px solid #2680EB
        border-radius: 15px
        padding: 15px 10px
        +media-query(md)
          margin: 15px 0 0 0
          font-size: 1.2rem
      .__list
        margin: 25px 0 0 0