@use "../foundation/import" as *

.l-global-navigation
  display: flex
  justify-content: flex-end
  padding: 0 0 15px 0 
  border-bottom: 1px solid $color-cyan
  +media-query(md)
    display: none
  .__list
    display: flex
    justify-content: flex-end
    align-items: center
    .__item
      &:not(:last-child)
        margin: 15px 60px 0 0
      &:last-child
        margin: 15px 0 0 0
      .__link
        font-weight: bold
        text-decoration: none
        font-size: 1.7rem

.l-content-navigation
  display: none
  padding: 0 20px
  margin: 0 0 30px 0
  +media-query(md)        
    display: block
  .__list
    .__item
      .__link
        display: block
        font-weight: bold
        text-decoration: none
        padding: 5px 0
        border-bottom: 1px solid $color-cyan
        position: relative
        &::before
          content: url(../img/site/icon_down_min.svg)
          position: absolute
          right: 0
          top: calc(50% - 8px)
