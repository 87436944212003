@use "../../foundation/import" as *

/* -------------------------------
 *  heading components
 * ---------------------------- */
.u-heading-content-title-level-1
  text-align: center
  .__text
    display: inline-block
    min-width: 530px
    margin: 0 auto
    background: $color-cyan
    color: $color-white
    font-size: 2.5rem
    font-weight: bold
    padding: 10px 0
    border-radius: 99em
    text-decoration: none
    +media-query(md)
      min-width: auto
      width: 100%
      font-size: 1.6rem
      padding: 8px 0
  &.u-heading-content-title-level-1--long
    .__text
      min-width: 100%
      padding: 10px 0
  &.u-heading-content-title-level-1--link
    .__text
      background: $color-cyan-dark
      span 
        position: relative
        &::after
          content: ""
          width: 28px
          height: 28px
          background: url(../img/site/icon_next_white_min.svg) no-repeat center center/contain
          position: absolute
          right: calc(-30px - 1em)
          top: calc(50% - 14px)
          +media-query(md)
            width: 18px
            height: 18px
            top: calc(50% - 9px)

.u-heading-content-title-level-2
  text-align: center        
  font-size: 2.0rem
  padding: 0 0 5px 0
  border-bottom: 2px solid
  +media-query(md)
    font-size: 1.2rem
  &.u-heading-content-title-level-2--green
    border-bottom-color: $color-green
  &.u-heading-content-title-level-2--darkgreen
    border-bottom-color: $color-darkgreen
  &.u-heading-content-title-level-2--blue
    border-bottom-color: $color-blue
  &.u-heading-content-title-level-2--yellow
    border-bottom-color: $color-yellow

.u-heading-title-counter
  text-align: center
  .__element
    min-width: 250px
    height: 45px
    background: $color-white
    border: 2px solid $color-cyan
    border-radius: 99em
    position: relative
    display: inline-block
    +media-query(md)
      min-width: auto
      height: 28px
    &::before
      content: attr(data-number)
      position: absolute
      left: -1px
      top: -1px
      width: 44px
      height: 44px
      display: flex
      justify-content: center
      align-items: center
      line-height: 1
      background: $color-cyan
      border-radius: 100%
      color: $color-white
      font-weight: bold
      font-size: 2.8rem
      +media-query(md)
        width: 28px
        height: 28px
        font-size: 1.5rem
    .__text 
      font-size: 2.0rem
      line-height: 1
      display: flex
      justify-content: center
      align-items: center
      padding: 0 60px
      text-align: center
      width: 100%
      height: 100%
      position: relative
      +media-query(md)
        font-size: 1.2rem
        padding: 0 40px
      &::before,
      &::after
        content: ""
        position: absolute
        width: 0
        height: 0
        top: 100%
      &::before
        border: 18px solid transparent
        border-top-color: $color-cyan
        left: calc(50% - 18px)
        +media-query(md)
          border: 12px solid transparent
          left: calc(50% - 12px)
          border-top-color: $color-cyan
      &::after
        border: 16px solid transparent
        border-top-color: $color-white
        left: calc(50% - 16px)
        +media-query(md)
          border: 10px solid transparent
          left: calc(50% - 10px)
          border-top-color: $color-white

        