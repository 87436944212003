@use "../../foundation/import" as *

/* -------------------------------
 *  button components
 * ---------------------------- */
.u-button-contact-header
  display: flex
  justify-content: center
  align-items: center
  padding: 10px 15px
  background: $color-orange
  border-radius: 10px
  text-decoration: none
  .__icon
    margin: 0 15px 0 0
  .__text
    color: #fff
    font-weight: bold
    font-size: 1.7rem
    line-height: 1
    flex-shrink: 0

.u-button-download
  display: flex
  align-items: center
  justify-content: center
  background: $color-green-light
  border-radius: 99em
  padding: 20px 5px
  box-shadow: 0 3px 4px rgba(0,0,0,0.16) inset
  +media-query(md)
    padding: 10px 5px
  &.__button--fullheight
    height: 100%
  .__text
    text-align: center
    line-height: 1.4
    font-weight: bold  
    text-decoration: none
    +media-query(md)
      font-size: 1.2rem
    .__copy
      display: inline-block
      &:last-child
        +media-query(md)
          margin: 0 0 0 0.5em
    br
      +media-query(md)
        display: none
  .__image
    margin: 0 0 0 10px
    +media-query(md)
      width: 20px

.u-button-external
  background: $color-cyan-light
  border-radius: 99em    
  padding: 20px
  text-decoration: none
  display: flex
  justify-content: center
  align-items: center
  box-shadow: 0 3px 4px rgba(0,0,0,0.16) inset
  +media-query(md)
    padding: 15px
  .__text
    display: inline-block
    font-size: 1.7rem
    font-weight: bold
    +media-query(md)
      font-size: 1.2rem

.u-button-kokodenka,
.u-button-inquiry 
  min-height: 80px

.u-button-kokodenka
  display: flex
  align-items: center
  justify-content: space-between
  background: $color-beige      
  box-shadow: 0 3px 6px rgba(0,0,0,0.16) 
  border-radius: 24px
  text-decoration: none
  padding: 15px
  +media-query(md)
    justify-content: center
    border-radius: 99em
  .__box
    flex-grow: 1
    +media-query(md)
      display: flex
      justify-content: center
      align-items: center
      flex-grow: 0
    .__text,
    .__thumbnail
      display: block
      text-align: center
    .__text
      font-weight: bold
      font-size: 1.8rem
      +media-query(md)
        font-size: 1.2rem
    .__thumbnail
      margin: 10px 0 0 0
      +media-query(md)
        margin: 0 0 0 10px
        width: 80px
  .__icon
    +media-query(md)
      margin: 0 0 0 10px
      width: 18px

.u-button-inquiry      
  display: flex
  align-items: center
  justify-content: center
  background: $color-orange  
  box-shadow: 0 3px 4px rgba(0,0,0,0.16) inset
  border-radius: 24px
  text-decoration: none
  padding: 10px
  &.__button--fullheight
    height: calc(100% - 38px)
    +media-query(md)
      height: auto
  .__image
    margin: 0 0 0 15px
    +media-query(md)
      width: 24px
  .__text .__copy
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    color: #fff
    font-weight: bold
    font-size: 2.0rem
    line-height: 1.2
    +media-query(md)
      flex-direction: row
      font-size: 1.3rem
    .__sub
      color: $color-black
      font-size: 0.9em