@use "../../foundation/import" as *

/* -------------------------------
 *  form components
 * ---------------------------- */
::placeholder
  color: $color-light-gray

.u-form-contact
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  textarea
    border: 1px solid $color-gray
    padding: 20px
    border-radius: 10px
    width: 100%
    font-size: 1.6rem
    +media-query(md)
      padding: 12px
  textarea
    min-height: 330px
    +media-query(md)
      min-height: 180px
  label
    position: relative
  input[type="checkbox"],
  input[type="radio"]
    padding: 0 0 0 1.5em
    line-height: 1
    & + span
      display: inline-block
      margin: 0 40px 0 0
      line-height: 1
      font-size: 1.8rem
      +media-query(md)
        font-size: 1.4rem
    &::before,
    &::after
      content: ""
      position: absolute
      left: 0
      top: 0
    &::before
      width: 16px
      height: 16px
      border: 1px solid $color-gray
  input[type="checkbox"]
    &::after
      width: 14px
      height: 10px
      border-left: 4px solid $color-white
      border-bottom: 4px solid $color-white
      transform: translate(1px, 1px) rotate(-45deg)
      visibility: hidden
    &:checked
      &::before
        background: $color-gray
      &::after
        visibility: visible
  input[type="radio"]
    &::before,
    &::after
      border-radius: 100%
    &::after
      left: 2px
      top: 2px
      width: 12px
      height: 12px
    &:checked
      &::after
        background: $color-gray
  input[type="submit"]
    display: block
    width: 100%
    height: 50px
    background: $color-cyan
    color: $color-white
    border-radius: 99em
    font-size: 2.5rem
    font-weight: bold
    +hover-style()
      cursor: pointer
    +media-query(md)
      height: 40px
      font-size: 1.3rem
  .__form-submit-area        
    .__caption
      margin: 30px 0 0 0
      font-size: 2.0rem
      +media-query(md)
        margin: 20px 0 0 0
        font-size: 1.2rem
    .__agree
      display: flex
      justify-content: center
      padding: 40px 0 30px 0
      border: 1px solid $color-gray
      padding: 40px 0
      border-radius: 10px
      margin: 60px 0 0 0
      input[type="checkbox"] + span 
        font-size: 2.0rem
      +media-query(md)
        padding: 15px 0
        input[type="checkbox"] + span 
          font-size: 1.6rem
    .__about
      margin: 40px 0
      font-size: 2.0rem
      +media-query(md)
        margin: 15px 0 20px 0
        font-size: 1.2rem
      .__text
        display: block
      .__link
        color: $color-cyan
        text-decoration: none
    .__submit
      position: relative
      +media-query(md)
      .__icon
        position: absolute
        width: 24px
        height: 24px
        background: $color-white
        border-radius: 100%
        right: 15px
        // top: calc(50% - 12px)
        top: calc(50% - 28px)
        +hack-ie11
          top: calc(50% - 12px)
        +media-query(md)
          width: 16px
          height: 16px
          // top: calc(50% - 8px)
          top: calc(50% - 24px)
          +hack-ie11
            top: calc(50% - 8px)
        &::before
          content: ""
          width: 0
          height: 0
          border: 8px solid transparent
          border-left-color: $color-cyan
          position: absolute
          top: calc(50% - 8px)
          left: calc(50% - 3px)
          +media-query(md)
            border: 6px solid transparent
            border-left-color: $color-cyan
            top: calc(50% - 6px)
            left: calc(50% - 2px)
    .__information-list
      margin: 60px 0 0 0
      +media-query(md)
        margin: 20px 0 0 0
      .__item
        padding: 0 0 0 1em
        position: relative
        &::before
          content: "・"
          position: absolute
          left: 0
          top: 0
        &.__item--large
          font-weight: bold
          font-size: 2.0rem
          margin: 0 0 2em 0
          +media-query(md)
            font-size: 1.4rem
            margin: 0 0 1em 0
        &.__item--small
          font-size: 1.6rem
          +media-query(md)
            font-size: 1.2rem
  .u-textarea-low
    height: 90px
    min-height: auto
  .__input-message
    margin: 30px 0 0 0