@use "../foundation/import" as *

/* -------------------------------
 *  main
 * ---------------------------- */
.l-main
  grid-area: main

/* -------------------------------
 *  aside
 * ---------------------------- */
.l-aside
  .__block-depth-1
    padding: 35px 0
    max-width: 646px
    margin: 0 auto
    +media-query(md)
      padding: 10px 0 25px 0